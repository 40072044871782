<template>
  <v-container fluid style="margin: 0; padding: 0;">
    <Menu style="position:fixed; height: 50px; z-index: 1;" />
    <div style="min-height: calc(100vh - 70px);">
      <v-container
        fluid
        style="height: calc(100vh - 70px); margin:0px; padding:0px; min-height: inherit; "
        class="d-flex flex-row"
      >
        <div class="list-body">
          <v-card-title style="width: 100%" class="ml-10">
            <label class="item_title">Edit Event</label>
          </v-card-title>
          <v-card-text style="flex-grow: 1;">
            <div class="create-post">
              <div class="container">
                <div class="event-info">
                  <input
                    type="text"
                    placeholder="Enter Event Title"
                    v-model="event.title"
                    style="flex-grow: 1"
                  />
                  <div class="upload-file">
                    <label for="event-photo">Upload Cover Photo</label>
                    <input
                      ref="eventPhoto"
                      id="event-photo"
                      @change="fileChange"
                      class="d-none"
                      type="file"
                      accept="image/*"
                    />
                  </div>
                </div>
                <div>
                  <v-img
                    :src="selectedImage ? selectedImage : event.cover"
                    v-if="selectedImage || event.cover"
                    width="100%"
                    max-height="360"
                  />
                </div>
                <v-select
                  v-model="event.categories"
                  label="Categories"
                  multiple
                  :items="allCategories"
                  chips
                  class="mt-3"
                  clearable
                >
                  <template #selection="{ item }">
                    <v-chip close @click:close="removeTag(item)" class="my-1">{{
                      item
                    }}</v-chip>
                  </template>
                </v-select>
                <div class="editor">
                  <vue-editor
                    :editorOptions="editorSettings"
                    v-model="event.html"
                    useCustomImageHandler
                    @image-added="imageHandler"
                  />
                </div>
                <div class="event-actions">
                  <v-btn
                    @click="uploadEvent"
                    :loading="loading"
                    class="router-button"
                    >Publish Event</v-btn
                  >
                  <v-btn @click="onEventPreview" class="router-button"
                    >Post Preview</v-btn
                  >
                </div>
                <event-preview
                  :dialog="previewDialog"
                  :onClose="onClosePreview"
                  :event="event"
                />
                <v-snackbar v-model="error">
                  {{ errorMsg }}
                </v-snackbar>
              </div>
            </div>
          </v-card-text>
        </div>
        <div class="edit-right-section">
          <div
            color="white"
            class="mt-5 pb-5"
            style="width: 330px; max-width: 330px"
          >
            <div style="min-height: 400px; overflow-y: scroll;">
              <div v-for="event in events" :key="event._id" class="mb-3">
                <v-card style="width: 100%" @click="goToDetails(event._id)">
                  <v-card-subtitle class="d-flex flex-row">
                    <div style="width: 100px; height: 100px;">
                      <v-img :src="event.cover" width="100%" height="100%" />
                    </div>
                    <div
                      style="word-break: break-word; margin-left: 12px; font-weight: bold;"
                    >
                      {{ event.title }}
                    </div>
                  </v-card-subtitle>
                </v-card>
              </div>
            </div>
          </div>
        </div>
      </v-container>
    </div>
  </v-container>
</template>
<script>
import Menu from "../../components/core/Menu.vue";
import { mapActions, mapGetters } from "vuex";
import Quill from "quill";
import EventPreview from "./EventPreview.vue";
window.Quill = Quill;
const ImageResize = require("quill-image-resize-module").default;
Quill.register("modules/imageResize", ImageResize);
const VideoResize = require("quill-video-resize-module").default;
Quill.register("modules/videoResize", VideoResize);

export default {
  components: { Menu, EventPreview },
  data() {
    return {
      file: null,
      error: null,
      errorMsg: null,
      loading: null,
      event: { title: "", html: "" },
      editorSettings: {
        modules: {
          imageResize: {},
          videoResize: {},
        },
      },
      selectedImage: null,
      previewDialog: false,
      allCategories: [
        "Yoga classes",
        "Diabetes prevention",
        "Running groups",
        "Healthy eating",
        "Healthy recipes",
        "Exercise videos",
        "Community events",
        "Volunteering",
      ],
      events: [],
    };
  },
  computed: {
    ...mapGetters({
      keyword: "auth/getKeyword",
    }),
  },
  methods: {
    ...mapActions({
      uploadEventImage: "event/uploadEventImage",
      updateEvent: "event/updateEvent",
      fetchAllEvents: "event/fetchAllEvents",
      getEventDetails: "event/getEventDetails",
      fetchAllCategories: "variable/fetchAllCategories",
    }),
    fileChange() {
      if (this.$refs.eventPhoto.files.length != 0) {
        this.file = this.$refs.eventPhoto.files[0];
        this.selectedImage = URL.createObjectURL(this.file);
        this.event.cover = this.selectedImage;
      }
    },
    onEventPreview() {
      this.previewDialog = true;
    },
    onClosePreview() {
      this.previewDialog = false;
    },
    imageHandler(file, Editor, cursorLocation, resetUploader) {
      var formData = new FormData();
      formData.append("file", file);
      this.uploadEventImage(formData)
        .then((res) => {
          Editor.insertEmbed(cursorLocation, "image", res);
          resetUploader();
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data.message);
          } else {
            console.log(error);
          }
        });
    },
    uploadEvent() {
      if (this.event.title.length !== 0 && this.event.html.length !== 0) {
        if (this.file || this.event.cover) {
          var formData = new FormData();
          formData.append("_id", this.event._id);
          formData.append("html", this.event.html);
          formData.append("title", this.event.title);
          formData.append("text", this.extractContent(this.event.html));
          formData.append("categories", this.event.categories.join());
          if (this.file) {
            formData.append("cover", this.file);
          }

          this.loading = true;
          this.updateEvent(formData)
            .then((res) => {
              console.log(res);
              this.loading = false;
              this.$router.push({
                name: "event-details",
                query: { _id: res._id },
              });
              // this.event = { title: "", html: "" };
              // this.file = null;
              // this.selectedImage = null;
              // this.loadEvents();
            })
            .catch((error) => {
              this.loading = false;
              console.log(error.response.data.message);
            });
        } else {
          this.error = true;
          this.errorMsg = "Please ensure you uploaded a cover photo!";
          setTimeout(() => {
            this.error = false;
          }, 5000);
        }
        return;
      }
      this.error = true;
      this.errorMsg = "Please ensure Event Title & Event Post has been filled!";
      setTimeout(() => {
        this.error = false;
      }, 5000);
      return;
    },
    goToDetails(_id) {
      this.$router.push({ name: "event-details", query: { _id } });
    },
    loadEvents() {
      this.loading = true;
      this.fetchAllEvents()
        .then((res) => {
          this.loading = false;
          this.events = res;
        })
        .catch((error) => {
          console.log(error.response.data.message);
        });
    },
    loadData() {
      this.getEventDetails({ _id: this.$route.query._id })
        .then((res) => {
          this.event = res;
          console.log(res);
        })
        .catch((error) => {
          console.log(error.response.data.message);
        });
    },
    removeTag(item) {
      this.event.categories = this.event.categories.filter((c) => c != item);
    },
    extractContent(s) {
      var span = document.createElement("span");
      span.innerHTML = s;
      return span.textContent || span.innerText;
    },
  },
  watch: {
    "$route.query._id": function(newValue) {
      if (newValue) {
        this.event = null;
        this.loadData();
        this.loadEvents();
      }
    },
  },
  mounted() {
    this.loadEvents();
    this.loadData();
    this.loading = true;
    this.fetchAllCategories()
      .then((res) => {
        this.loading = false;
        this.allCategories = res.map((c) => c.title);
      })
      .catch((error) => {
        this.loading = false;
        if (error.response != undefined && error.response.status === 401) {
          this.tokenDialog = true;
        } else {
          console.log(error);
        }
      });
  },
};
</script>
<style lang="scss">
.item_title {
  color: #6e3dc6;
  font-family: "Poppins-Bold";
  font-size: 25px;
  padding-left: 5px;
  padding-top: 10px;
}
.item_description {
  color: #482684;
  /* font-family: 'Poppins-Bold'; */
  font-size: 18px;
  padding-left: 7px;
}
.referral_title {
  color: #f0a000;
  font-family: "Poppins-SemiBold";
  font-size: 25px;
}

.create-post {
  position: relative;
  height: 100%;
  width: 100%;
  button {
    margin-top: 0;
  }
  .router-button {
    text-decoration: none;
    color: #fff;
  }
  .event-info {
    label,
    button,
    .router-button {
      transition: 0.5s ease-in-out all;
      align-self: center;
      font-size: 14px;
      cursor: pointer;
      border-radius: 20px;
      padding: 12px 24px;
      color: #fff;
      background-color: #fea800;
      text-decoration: none;
      &:hover {
        background-color: rgba(48, 48, 48, 0.7);
      }
    }
  }
  .event-actions {
    label,
    button,
    .router-button {
      transition: 0.5s ease-in-out all;
      align-self: center;
      font-size: 14px;
      cursor: pointer;
      border-radius: 20px;
      padding: 12px 24px;
      color: #fff;
      background-color: #fea800;
      text-decoration: none;
      &:hover {
        background-color: rgba(48, 48, 48, 0.7);
      }
    }
  }
  .container {
    position: relative;
    height: 100%;
    padding: 10px 25px 20px;
    display: flex;
    flex-direction: column;
  }
  // error styling
  .invisible {
    opacity: 0 !important;
  }
  .err-message {
    width: 100%;
    padding: 12px;
    border-radius: 8px;
    color: #fff;
    margin-bottom: 10px;
    background-color: #303030;
    opacity: 1;
    transition: 0.5s ease all;
    p {
      font-size: 14px;
    }
    span {
      font-weight: 600;
    }
  }
  .event-info {
    display: flex;
    margin-bottom: 32px;
    input:nth-child(1) {
      min-width: 300px;
    }
    input {
      transition: 0.5s ease-in-out all;
      padding: 10px 4px;
      border: none;
      border-bottom: 1px solid #303030;
      &:focus {
        outline: none;
        box-shadow: 0 1px 0 0 #303030;
      }
    }
    .upload-file {
      margin-left: 16px;
      position: relative;
      display: flex;
      input {
        display: none;
      }
      .preview {
        margin-left: 16px;
        text-transform: initial;
      }
      span {
        font-size: 12px;
        margin-left: 16px;
        align-self: center;
      }
    }
  }
  .editor {
    height: 100%;
    display: flex;
    flex-direction: column;
    .quillWrapper {
      position: relative;
      display: flex;
      flex-direction: column;
      height: 100%;
      #quill-container {
        max-height: 60vh !important;
      }
    }
    .ql-container {
      display: flex;
      flex-direction: column;
      height: 100%;
      overflow: scroll;
    }
    .ql-editor {
      padding: 20px 16px 30px;

      .ql-video {
        pointer-events: none;
      }
    }
  }
  .event-actions {
    margin-top: 32px;
    button {
      margin-right: 16px;
      background-color: #fea800 !important;
    }
  }
}

/* .v-text-field >>> input {
    font-size: 10px;
  } */
.v-text-field >>> .v-text-field__details {
  display: none;
}

::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px light-grey;
  border-radius: 3px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #feb934;
  border-radius: 3px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #f5bd4d;
}

.v-text-field >>> input#map::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: transparent;
}
.v-text-field >>> input#map::-moz-placeholder {
  /* Firefox 19+ */
  color: transparent;
}
.v-text-field >>> input#map:-ms-input-placeholder {
  /* IE 10+ */
  color: transparent;
}
.v-text-field >>> input#map:-moz-placeholder {
  /* Firefox 18- */
  color: transparent;
}
.v-text-field >>> input#map::placeholder {
  color: transparent;
}

.v-data-table >>> .selected {
  background-color: #fff4e0 !important;
}

.list-body {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  min-width: 200px;
  margin-left: 60px;
  margin-right: 20px;
  align-items: center;
  padding-top: 50px;
}
.edit-right-section {
  max-height: 0;
  overflow-y: hidden;
  position: sticky;
  min-height: inherit;
  overflow-x: hidden;
  top: 118px;
  background: #f4f9ff;
  width: 380px;
  min-width: 380px;
  padding-top: 50px;
}
.edit-right-section:hover {
  overflow-y: auto;
}
.edit-right-section::-webkit-scrollbar {
  width: 8px;
  transition-duration: 0.5s;
}

/* Track */
.edit-right-section::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px light-grey;
  border-radius: 3px;
}
/* Handle */
.edit-right-section::-webkit-scrollbar-thumb {
  background: rgba(240, 160, 0, 0.3);
  border-radius: 3px;
}

/* Handle on hover */
.edit-right-section::-webkit-scrollbar-thumb:hover {
  background: #f0a000;
}
</style>
