<template>
  <v-dialog v-model="dialog" persistent width="1200">
    <v-card style="max-height: 80vh; display: flex; flex-direction: column;">
      <v-card-title class="d-flex" style="justify-content: space-between;">
        Preview
        <v-btn icon @click="onClose">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text style="flex-grow: 1; overflow-y: scroll;">
        <div class="feed-body">
          <div class="feed-contents quillWrapper" v-if="event">
            <v-img :src="this.event.cover" alt="" width="100%" class="mt-8" />
            <div class="d-flex align-center">
              <h2 class="mt-5">{{ this.event.title }}</h2>
              <v-spacer />
            </div>
            <div
              class="post-content ql-editor mt-15"
              v-html="this.event.html"
            ></div>
          </div>
          <v-progress-circular
            indeterminate
            color="amber"
            v-else
            class="mt-6"
          />
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapState } from "vuex";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.core.css";
import "quill/dist/quill.bubble.css";
export default {
  name: "EventPreview",
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    event: Object,
    onClose: Function,
  },
  methods: {
    ...mapActions("event", {
      getEventDetails: "getEventDetails",
    }),
  },
  computed: {
    ...mapState("auth", {
      profile: "profile",
    }),
  },
};
</script>
<style lang="scss" scoped>
.feed-body {
  width: 100%;
  min-width: 200px;
  margin-left: 0px;
  margin-right: 0px;
  display: flex;
  justify-content: center;
  .feed-contents {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-bottom: 20px;
    padding-left: 20px;
    padding-right: 20px;
  }
  h4 {
    font-weight: 400;
    font-size: 14px;
    margin-bottom: 24px;
  }
}

.post-content {
  overflow: hidden;
}
</style>
